import React from "react";
import "./styles.scss";
import Iframe from "react-iframe";
import Marquee from "react-fast-marquee";



function Welcome() {
   
  return (
 <>
<div className="admission_btn ">
    <a href="https://forms.gle/m3Wnci9Djh8YJRDRA" target="_blank" className="btn btn-warning"><i className="fa fa-arrow-down ms-3"></i> Admissions Open</a>
</div>

<div className="container">
<div className="container-xxl py-5">
  
  <div className="row g-4">
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item text-center pt-3">
              <div className="p-4">
                  <i className="fa fa-3x fa-graduation-cap  mb-4"></i>
                  <h5 className="mb-3 "> Experienced Professors</h5>
                  <p> </p>
              </div>
          </div>
      </div>
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item text-center pt-3">
          
              <div className="p-4">
                  <i className="fa fa-3x fas fa-university mb-4"></i>
                  <h5 className="mb-3">Best Infrastructure</h5>
                 
                  <p></p>
              </div>
                               </div>
      </div>
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item text-center pt-3">
              <div className="p-4">
                  <i className="fa fa-3x 	fas fa-tv  mb-4"></i>
                  <h5 className="mb-3">Smart Class Rooms</h5>
                  <p></p>
              </div>
          </div>
      </div>
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item text-center pt-3">
              <div className="p-4">
              {/* <FontAwesomeIcon icon={faMicroscope}  size="4x"/> */}
                  <i className="fa fa-3x fas fa-flask mb-4"></i>
                  <h5 className="mb-3">Equipped Laboratories</h5>
                  <p></p>
              </div>
          </div>
  
      
  </div>
  <br/>
  <div className="col-12">
  <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
      <h5 className="section-title bg-white text-center text-primary px-3">About Our College</h5>
      <h1 className="mb-5"> Welcome To JNCW  </h1>
  </div>
<p>
Shri Venkateswara Trust was established in the year 2009. The Present Chairman Dr.K.Jayaramam is an eminent Educationalist and Philanthropist and his motto is Empowering Rural Women through Education and Training. Shri Venkateswara Trust has Created a position for itself in the sphere of Women's Education in Kallkurichi district, TamilNadu by Rendering Remarkable Service and Excellent Contribution in the field of Education. It has a good track record in promoting Women's Education for more than a decade. With all its Zeal, Enthusiasm, Plenteous support of Parents and Students, currently, The Trust has Developed 2 more Institutions as a part of JSA Group of Institutions.
</p>
 <p>
 <b className="text-danger">Jawaharlal Nehru College For Women (Arts & Science), Ulundurpet </b>was founded during 2009 by Shri Venkateswara Trust and is now being run by the able guidance of the Philanthropist Dr.K.Jayaramam, Chairman and Technocrat Dr.JSA Arun, Secretary, of JSA Group of Institutions The College is located in Trichy-Chennai National Highway-45, Pali, Ulundurpet, Kallkurichi District. 
</p>
  <p>
  It has an Extensive Campus with all Modern Infrastructure facilities Which spread over in 15 acres of land. It is a Self Financing Institution, Affiliated to Annamalai University, Chidambaram. The College Offers 13 Under Graduate and 4 Post Graduate Courses in Arts and Science Stream.
  The College has a Team of Qualified Dedicated and well Experienced Faculty Members. Academic studied are supplemented with Vocational Skill Development, Co-Curricular and Extra Curricular Activities in which College has Excellent Track record. The College provides various facilities such as Smart Classrooms, Digitalized library, Language Lab, Well Equipped Laboratories, Technically Advanced Auditorium, Easy Accessible Transport to all villages within the District, Extensive Playground, Safe and Secured In-Campus Hostel. It also Provides additional Entrepreneurship Programs. The college provides placement assistance to the students.
    
</p>
<p>
  The college strives for inculcating Discipline, Values and imparting regular Training among students for their future success.
</p>
</div>
</div>

</div>


<div className="row row-cols-1 row-cols-md-2 g-4">
<div className="col-lg-6">
<div class="card">
<div class="card-header text-center  text-white">Upcoming Event</div>
<div className="upper-marquee">
<ul className="list-group list-group-flush">
<a href=" " className="scroll-up">The Department of Commerce (Computer Application) proudly presents One Day Technical Symposium 25 - 07- 2024. </a>
{/* <a href=" " className="scroll-up"> 123 FRESH FIESTA'24 1-Year UG Orientation Programme</a> */}
</ul>
</div>
</div>
</div>
<div className="col-lg-6">
<div class="card">
  <div class="card-header text-center  text-white">Annousenment</div>

  <ul className="upper-marquee">
<a href="/university-rankers" className="scroll-up"> University Rankers & Gold Medalists Top 10 students click here</a>
      </ul>
</div>
</div>

      </div>


<div className="col-md-12" style={{ height: "30rem" }}>
 <Iframe 
width="100%"
height="100%"
src="https://www.youtube.com/embed/L4BDjqMs3No?si=2D9WuG4LeTnlSedt" 
title="YouTube video player"
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></Iframe>
</div>

<br/>
  <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
      <h5 className="section-title bg-white text-center text-primary px-3">Courses Offered</h5>
      <h1 className="mb-5">  </h1>
  </div>
<div className="row">
<div className="col-lg-6">
<div class="card">
  <div class="card-header text-center bg-success text-white">UG Courses</div>
  <ul class="list-group list-group-flush">
    <a class="list-group-item text-center" href="courses-baenglish">B.A.(English)</a>
    <a class="list-group-item text-center" href="/courses-baTamil">B.A.(Tamil)</a>
    <a class="list-group-item text-center" href="/courses-bba ">B.B.A.(Business Administration)</a>
    <a class="list-group-item text-center" href="/courses-commerce ">B.Com.(General)</a>
    <a class="list-group-item text-center" href="/courses-b.com.ca">B.Com.(CA)</a>
    <a class="list-group-item text-center" href="/courses-bscmaths ">B.Sc.(Mathematics)</a>
    <a class="list-group-item text-center" href="/courses-bscphysics ">B.Sc. (Physics)</a>
    <a class="list-group-item text-center" href="/courses-bscchemistry ">B.Sc.(Chemistry)</a>
    <a class="list-group-item text-center" href="/courses-bscmicrobiology ">B.Sc.(Microbiology)</a>
    <a class="list-group-item text-center" href="/courses-bsccomputer ">B.Sc.(Computer Science)</a>
    <a class="list-group-item text-center" href="/courses-computerapplication ">B.C.A.(Computer Application)</a>
  </ul>
</div>
</div>
<div className="col-lg-6">
<div class="card">
  <div class="card-header text-center bg-success text-white">PG Courses</div>
  <ul  class="list-group list-group-flush">
    <a class="list-group-item text-center" href="courses-baenglish ">M.A.(English)</a>
    <a class="list-group-item text-center" href="/courses-commerce ">M.Com</a>
    <a class="list-group-item text-center" href="/courses-bscmaths ">M.Sc. (Mathematics)</a>
    <a class="list-group-item text-center" href="/courses-bsccomputer">M.Sc.(Computer Science)</a>
  </ul>
</div>
</div>
</div>

</div>
 

   
 
 </>





  );
}

export default Welcome;
