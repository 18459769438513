import React from 'react';
import "./news.css";
import News1 from  "../../../assets/images/News/img_1 (8).png";
import News2 from  "../../../assets/images/News/img_1 (3).png";
import News3 from  "../../../assets/images/News/img_1 (7).png";
import Admission from "../../../assets/images/banner/course baner.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Sports from "../../../assets/images/gallery/2024/sports apirl/img (1).jpg";
import Sports1 from "../../../assets/images/gallery/2024/sports apirl/img (2).jpg";

import YogaDay from "../../../assets/images/News/Up coming/Img  (1).jpeg";
import FoodFestival from "../../../assets/images/gallery/2024/food fesitval june/Img (3).JPG";

import YogaDay1 from "../../../assets/images/gallery/2024/yoga day/Img (5).JPG";
import Yogaday2 from "../../../assets/images/gallery/2024/yoga day/Img (6).JPG";


function NewsUpdated() {  return (
    <>
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h5 className="section-title bg-white text-center text-primary px-3"> Upcoming Events </h5>
        <h1 className="mb-5"> Updates </h1>
      </div>
      <div class="container">
          <div class="row">
              {/* <!-- Blog entries--> */}
              <div class="col-lg-8">
                  {/* <!-- Featured blog post--> */}
                  <div class="card mb-4">
                      <a href="#!"><img class="card-img-top" src={Admission} alt="..." /></a>
                      <div class="card-body">
                          <div class="small text-muted">Apirl 1 2024</div>
                          <h2 class="card-title">Admissions Open 2024-2025</h2>
                          <p class="card-text">We are excited to announce that admissions for the upcoming academic session are now open at Jawaharlal Nehru College For Women,(Arts & Science) </p>
                          <a class="btn btn-primary" href="#!">Read more →</a>
                      </div>
                  </div>
                  {/* <!-- Nested row for non-featured blog posts--> */}
                  <div class="row">
                      <div class="col-lg-6">
                          {/* <!-- Blog post--> */}

                          <div className="card mb-4">
                                <a href="#!">
                                    <img className="card-img-top img-fluid custom-img" src={FoodFestival} alt="Sports Winners Prize Distribution" />
                                </a>
                                <div className="card-body">
                                    <div className="small text-muted">June 22 2024</div>
                                    <h2 className="card-title h4">Food Festival Celebration</h2>
                                    <p className="card-text"></p>
                                    <a className="btn btn-primary" href="https://jncw.in/gallery/2024">Read more →</a>
                                </div>
                            </div>


                          <div className="card mb-4">
                                <a href="#!">
                                    <img className="card-img-top img-fluid custom-img" src={YogaDay1} alt="Sports Winners Prize Distribution" />
                                </a>
                                <div className="card-body">
                                    <div className="small text-muted">June 21 2024</div>
                                    <h2 className="card-title h4">International Yoga Day Celebration</h2>
                                    <p className="card-text"></p>
                                    <a className="btn btn-primary" href="https://jncw.in/gallery/2024">Read more →</a>
                                </div>
                            </div>


                          <div className="card mb-4">
                                <a href="#!">
                                    <img className="card-img-top img-fluid custom-img" src={Sports1} alt="Sports Winners Prize Distribution" />
                                </a>
                                <div className="card-body">
                                    <div className="small text-muted">April 13 2024</div>
                                    <h2 className="card-title h4">Sports Winners Prize Distribution</h2>
                                    <p className="card-text">Sports Day Dazzle - 2024 celebration at Jawaharlal Nehru College for Women, Ulundurpet.</p>
                                    <a className="btn btn-primary" href="#!">Read more →</a>
                                </div>
                            </div>
                          <div class="card mb-4">
                              <a href="#!"><img class="card-img-top" src={ News3} alt="..." /></a>
                              <div class="card-body">
                                  <div class="small text-muted">Feb 21 2024</div>
                                  <h2 class="card-title h4">International Conference Inagrual </h2>
                                  <p class="card-text"></p>
                                  <a class="btn btn-primary" href="#!">Read more →</a>
                              </div>
                          </div>
                       
                      </div>
                      <div class="col-lg-6">
                          {/* <!-- Blog post--> */}

                          <div className="card mb-4">
                                <a href="#!">
                                    <img className="card-img-top img-fluid custom-img" src={Yogaday2} alt="Sports Event Inaugural" />
                                </a>
                                <div className="card-body">
                                    <div className="small text-muted">June 21 2024</div>
                                    <h2 className="card-title h4">International Yoga Day </h2>
                                    <p className="card-text"></p>
                                    <a className="btn btn-primary" href="https://jncw.in/gallery/2024">Read more →</a>
                                </div>
                            </div>  
                          <div className="card mb-4">
                                <a href="#!">
                                    <img className="card-img-top img-fluid custom-img" src={Sports} alt="Sports Event Inaugural" />
                                </a>
                                <div className="card-body">
                                    <div className="small text-muted">April 13 2024</div>
                                    <h2 className="card-title h4">Sports Event Inaugural</h2>
                                    <p className="card-text">Sports Day Dazzle - 2024 celebration at Jawaharlal Nehru College for Women, Ulundurpet.</p>
                                    <a className="btn btn-primary" href="#!">Read more →</a>
                                </div>
                            </div>
                          <div class="card mb-4">
                              <a href="#!"><img class="card-img-top" src={ News2} alt="..." /></a>
                              <div class="card-body">
                                  <div class="small text-muted">Feb 21 2024</div>
                                  <h2 class="card-title h4"> International Conference </h2>
                                  <p class="card-text"></p>
                                  <a class="btn btn-primary" href="#!">Read more →</a>
                              </div>
                          </div>
                      
                        
                            {/* Blog post */}
                         
                      </div>
                  </div>
                  {/* <!-- Pagination--> */}
                
              </div>
              {/* <!-- Side widgets--> */}
              <div class="col-lg-4">
              <div class="card mb-4">
                      <div class="card-header" style={{background:"#800000"}}>New Courses </div>
                      <div class="card-body">
                        <ul>
                            <li>B.Sc AI</li>
                            <li>B.Sc Psycholozy</li>
                            <li>B.Sc Nutritions & Diet</li>
                        </ul>
                      </div>
                  </div>
              <div class="card mb-4">
                      <div class="card-header" style={{background:"red"}}>Upcoming Event</div>
                 <img src={YogaDay} alt=''/>
      <div class="card-body"> <h4>International Yoga Day </h4> <div class="card-body small text-muted">21-06-2024 </div> </div>
                    
      </div>
      <div class="card mb-4">
      <img src={FoodFestival} alt=''/>
      <div class="card-body">Food Festival <div class="card-body small text-muted">21-06-2024 </div> </div>
                    </div>                
                    </div>  
                  {/* <div class="card mb-4">
                      <div class="card-header">Principal Message</div>
                      <div class="card-body">“Your talent determines what you can do. Your motivation determines how much you’re willing to do. Your attitude determines how well you do it.”
 —Lou Holtz
</div>
                  </div> */}
                  {/* <div class="card mb-4">
                      <div class="card-header">Recent Posts </div>
                    
                      <div className="card-body">
          <div className="row">
              <div className="col-sm-6">
                  <ul className="list-unstyled mb-0">
                      <li style={{ color: "blue" }}><a href="https://www.facebook.com/jncwomen/"><FontAwesomeIcon icon={faFacebookF} />   Facebook</a></li>
                        <li><a href="https://www.youtube.com/@jawaharlalnehrucollegeforwomen"  style={{color:"red"}}><FontAwesomeIcon icon={faYoutube} /> YouTube</a></li>
                  </ul>
              </div>
              <div className="col-sm-6">
                  <ul className="list-unstyled mb-0">
                      <li><a href="https://www.instagram.com/jncw.in_/"  style={{color:"#F56040"}}><FontAwesomeIcon icon={faInstagram} /> Instagram</a></li>
                  </ul>
              </div>
          </div>
      </div>

                  </div> */}
                 
              </div>
          </div>
   
      <br/>
     
      
    </>
  );
}

export default NewsUpdated;
